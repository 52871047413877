import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "../HOME/Navbar";
import Footer from "../FOOTER/Footer";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import NavComponent from "../HOME/Navbar";

export default function Ourservice() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.refresh();
      const elements = document.querySelectorAll("[data-aos]");

      elements.forEach((element) => {
        const top = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight - 100) {
          element.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <Helmet>
    <meta name="title" content="Dubai Apartments for Sale - Our Services" />
<meta name="description" content="Explore a wide selection of premium Dubai apartments for sale, from luxury apartments to investment opportunities. Find your dream home with expert guidance." />
<meta name="keywords" content="properties for sale in Dubai, Dubai luxury apartments, Dubai real estate, buy property in Dubai, investment properties Dubai, Dubai apartments for sale, real estate in Dubai, Dubai property market, buy home in Dubai, Dubai property investment" />
<meta property="og:title" content="Dubai Apartments for Sale - Our Services" />
<meta property="og:description" content="Explore a wide selection of premium Dubai apartments for sale, from luxury apartments to investment opportunities. Find your dream home with expert guidance." />
<meta property="og:image" content="https://www.dimoora.com/assets/ourbg.jpeg" />
<meta property="og:url" content="https://www.dimoora.com/our-services" />
<meta property="og:type" content="website" />
<title>Dubai Apartments for Sale - Our Services</title>


    </Helmet>
      <NavComponent />

      {/* bg-our Section */}
      <section className="bg-our">
        <Container>
          <div className="s-content">
            <div className="s-content-txt">
              <p className="s-txt-s">
                Explore our expert real estate services for buying, selling, and
                renting properties, tailored to meet your unique needs.
              </p>
            </div>
          </div>
        </Container>
      </section>

      {/* Content Section */}
      <section className="pb-5 section pt-3">
        <Container>
          <div>
            {/* Buy Section */}

            <Row className="gaps our-row-h" id="buy">
              <Col lg={6} md={6} sm={12}>
                <div className="our-div">
                  <img
                    src="assets/help-2.png"
                    className="img-fluid"
                    alt="img"
                     loading="lazy"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div>
                  <h2 className="txt-h">Buy A House</h2>
                  <p className="txt-h-1">
                    Looking to buy your dream home? Whether it's your first
                    property or an investment, we are here to make the process
                    smooth and stress-free. With access to a wide range of
                    listings, from modern apartments to luxurious houses, we
                    help you find a property that fits your needs and budget.
                    Our team is dedicated to guiding you every step of the way,
                    ensuring you make the best decision for your future.
                  </p>
                  <div className="d-flex justify-content-start align-items-end mt-1">
                    <Button
                      variant="contained"
                      className="btn-ani-1"
                      href="/find-home"
                      sx={{ textTransform: "none" }}
                    >
                      Find Home
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Sell Section */}

            <Row className="gaps row-our our-row-h" id="sell">
              <Col lg={6} md={6} sm={12}>
                <div>
                  <h2 className="txt-h">Sell A House</h2>
                  <p className="txt-h-1">
                    Ready to sell your property? We make selling simple and
                    efficient. Our expert team provides a comprehensive
                    valuation and works with you to prepare your home for
                    listing. Using modern marketing strategies, we ensure
                    maximum exposure to potential buyers. From negotiations to
                    closing, we handle all the details to ensure you get the
                    best price for your property in the shortest time possible.
                  </p>
                  <div className="d-flex justify-content-start align-items-end mt-1">
                    <Button
                      variant="contained"
                      className="btn-ani-1"
                      sx={{ textTransform: "none" }}
                      href="/place-ad"
                    >
                      Place an Ad
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="our-div">
                  <img
                    src="assets/help-3.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </Col>
            </Row>

            {/* Rent Section */}
            <Row className="gaps row-our our-row-h" id="rent">
              <Col lg={6} md={6} sm={12}>
                <div className="our-div">
                  <img
                    src="assets/help-1.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div>
                  <h2 className="txt-h">House an Rent</h2>
                  <p className="txt-h-1">
                    Finding the perfect rental property has never been easier.
                    Whether you're searching for a short-term rental or a
                    long-term home, we offer a wide range of rental properties
                    tailored to your needs. Our team connects you with
                    landlords, arranges viewings, and handles all the paperwork,
                    so you can focus on finding the right place to call home.
                  </p>
                  <div className="d-flex justify-content-start align-items-end mt-1">
                    <Button
                      variant="contained"
                      className="btn-ani-1"
                      sx={{ textTransform: "none" }}
                      href="/rent-property"
                    >
                      Find a Rental
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <Footer />
    </>
  );
}
