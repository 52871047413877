import React, { useEffect } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";
import NavComponent from "../HOME/Navbar";
import Footer from "../FOOTER/Footer";

const data = [
  {
    slug: "buy-property-in-dubai-from-india",
    date: "Mar 2025",
    title: "Buy Property in Dubai from India – Guide for Investors",
    image: "/assets/db-id.jpeg",
    text: "Dubai has ended up one of the pinnacle locations for real estate investment, attracting buyers from all over the international, inclusive of india. If you are questioning whether you could buy belongings in Dubai as an Indian citizen, the solution is a powerful yes! The metropolis offers lucrative opportunities for investors, commercial enterprise proprietors, and people looking for a second home.",
  },
  {
    slug: "buy-commercial-property-dubai",
    date: "Feb 2025",
    title: "Best Place to Buy Commercial Property in Dubai | Top Deals",
    image: "/assets/db-bc.jpeg",
    text: "Dubai operates as a worldwide business center which draws investors worldwide. The combination of prime positioning and free-from-taxes framework accompanied by prestigious infrastructure systems makes Dubai a superior place for business expansion. Extremely successful and lucrative commercial property investments in Dubai require that you pick the ideal location very carefully.",
  },
  {
    slug: "Why-Buy-Property-in-Dubai",
    date: "Oct 2024",
    title: "Why Buy Property in Dubai",
    image: "/assets/why-buy.jpg",
    text: "This has put Dubai amongst the biggest spenders when it comes to property buying both within the country as well as across the borders.",
  },
  {
    slug: "Buy-a-Dream-Apartments-in-Dubai",
    date: "Dec 2024",

    title: "Buy a Dream Apartments in Dubai",
    image: "/assets/buy-dream.jpg",
    text: "Dubai increasingly attracts global investors. It draws families,expats and investors. This article will serve as a complete guide to Dubai’s apartments for sale and can help you find a property of your dreams.",
  },
  {
    slug: "Discover-Your-Dream-Home-in-UAE",
    date: "Jan 2025",

    title: "Discover Your Dream Home in UAE",
    image: "/assets/UAE.jpg",
    text: "As a world-class global center blending luxurious lifestyles with sophisticated architectural aesthetics Dubai offers an ideal place to find your dream home. Your house search for Dubai UAE property represents a thrilling prospect in one of the world's most active urban centers.",
  },
];

const CardGrid: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavComponent />

      <Container className="card-div">
        <Row className="g-5 justify-content-start">
          {data.map((item) => (
            <Col
              key={item.slug}
              md={4}
              className="d-flex justify-content-center"
            >
              <Card className="my-5">
                <Card.Img variant="top" src={item.image} alt="img" loading="lazy" style={{height:"auto"}}/>
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text className="truncate-text">{item.text}</Card.Text>
                  <Link to={`/blogs/${item.slug}`}>
                    <Button className="btn-4"> Read More</Button>
                  </Link>
                  <p className="mt-2 mb-0">{item.date}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CardGrid;
