import React from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";

export default function Awards() {
  // Array of div content with image URLs
  const divs = [
    {
      id: 1,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/ZUP00722-1024x683.jpg",
    },
    {
      id: 2,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-19-1024x683.jpg",
    },
    {
      id: 3,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/DSC02244-1024x683.jpg",
    },
    {
      id: 4,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-17-1024x683.jpg",
    },
    {
      id: 5,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/ZUP00770-1-1024x683.jpg",
    },
    {
      id: 6,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/DSC02244-1024x683.jpg",
    },

    {
      id: 7,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/ZUP00746-1024x683.jpg",
    },
    {
      id: 8,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-20-1024x683.jpg",
    },
    {
      id: 9,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-20-1024x683.jpg",
    },
    {
      id: 10,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-20-1024x683.jpg",
    },
    {
      id: 11,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/Award-22-1024x683.jpg",
    },
    {
      id: 12,
      imgSrc:
        "https://houseandhedges.ae/wp-content/uploads/2024/03/DSC02196-1024x683.jpg",
    },
  ];

  return (
    <div>
      <section className="product-container pb-5 gaps section-1">
        <Container>
          <div className="d-flex flex-column gap-5 pt-3">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Carousel
                  indicators={true} // Dots navigation enabled
                  controls={false} // Remove buttons for next/prev
                  interval={2000} // Autoplay interval in ms
                  className="awards-div"
                >
                  {divs.map((div) =>
                    div.imgSrc ? ( // Ensure empty image is not displayed
                      <Carousel.Item key={div.id} className="side-slide">
                        <img
                          className="d-block w-100 img-awards"
                          src={div.imgSrc}
                          alt={`Slide ${div.id}`}
                          loading="lazy"
                        />
                      </Carousel.Item>
                    ) : null
                  )}
                </Carousel>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mt-3">
                  <h2 className="txt-c-1">Annual Broker Award 2023</h2>
                  <p className="txt-c">
                    Celebrate the legacy and beauty of our architectural
                    heritage with the prestigious Home and Heritage Awards! This
                    annual event recognizes outstanding efforts in preserving
                    and maintaining historical homes, buildings, and landmarks.
                    Whether you're a homeowner, architect, historian, or simply
                    an admirer of timeless beauty, the awards bring together a
                    community passionate about heritage conservation.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
}
