import React, { useEffect, useState } from "react";
import Nav from "../HOME/Navbar";
import { Container } from "react-bootstrap";
import { Card } from "antd";
import Footer from "../FOOTER/Footer";
import { useNavigate } from "react-router-dom";
import { data } from "../Data";
import NavComponent from "../HOME/Navbar";
import { Helmet } from "react-helmet";

// Define the Deal interface
interface Deal {
  id: number;
  imgSrc: string;
  content: string;
  para1: string;
  plan: string;
}

const deals: Deal[] = [
  {
    id: 1,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Binghatti-Ghost-at-Al-Jaddaf-570x370.jpg",
    content: "Twilight by Binghatti at Al Jaddaf",
    para1: "Dubai Hills Estate",
    plan: "offplan",
  },
  {
    id: 2,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
    content: "TGolf Hillside at Dubai Hills Estate",
    para1: "Dubai Hills Estate",
    plan: "on plan",
  },
  {
    id: 3,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-570x370.png",
    content: "Oasiz by Danube at Dubai Silicon Oasis",
    para1: "Dubai Silicon Oasis",
    plan: "offplan",
  },
  {
    id: 4,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Lavita-at-The-Oasis-5-570x370.jpg",
    content: "Lavita at The Oasis",
    para1: "The Oasis",
    plan: "on plan",
  },
  {
    id: 5,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
    content: "The Rings by PMR",
    para1: "Dubai Water Canel,Jumeria",
    plan: "offplan",
  },
  {
    id: 6,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/The-Rings-by-PMR-19-570x370.jpg",
    content: "The Beach Collection Villas by Nakhee",
    para1: "Dubai Water Canel,Jumeria",
    plan: "onplan",
  },
  {
    id: 7,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
    content: "Hillcrest at Town Square Dubai",
    para1: "Dubaiiland",
    plan: "current plan",
  },
  {
    id: 8,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
    content: "Golf Hillside at Dubai Hills Estatef",
    para1: "DubaiHillEstate,Dubai",
    plan: "onplan",
  },
  {
    id: 9,
    imgSrc:
      "https://houseandhedges.ae/wp-content/uploads/2024/09/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-570x370.png",
    content: "Oasiz by Danube at Dubai Silicon Oasis",
    para1: "Dubai Silicon Oasis",
    plan: "offplan",
  },
];

export default function Filter() {
  const navigate = useNavigate();
  const handleNav = (id: any) => {
    navigate(`/apartments-for-sale-in-dubai-on-plan/${id}`);
  };

  const [datas, setDatas] = useState<any>([]);

  useEffect(() => {
    const d = data.filter((val) => val.category === "off plan");
    setDatas(d);
  }, []);

  return (
    <>
      <NavComponent />
      <Helmet>
      <title>Our Services | Buy Properties in Dubai Off Plan with Dimoora</title>
      <meta
        name="description"
        content="Explore Dimoora's services to help you find properties for sale in Dubai. From luxury homes to investment options, we’ve got you covered."
      />
      <meta
        name="keywords"
        content="Dubai property services, properties for sale in Dubai, Dubai real estate services"
      />
      <meta name="author" content="Dimoora" />
      <meta name="robots" content="index, follow" />
      <meta
        property="og:title"
        content="Our Services | Buy Properties in Dubai Off Plan with Dimoora"
      />
      <meta
        property="og:description"
        content="Explore Dimoora's services to help you find properties for sale in Dubai. From luxury homes to investment options, we’ve got you covered."
      />
      <meta
        property="og:image"
        content="https://www.dimoora.com/assets/ourbg.jpeg"
      />
      <meta
        property="og:url"
        content="https://www.dimoora.com/buy-properties-in-dubai-off-plan"
      />
      <meta property="og:type" content="website" />
    </Helmet>
      <section className="gaps-3 section pb-5 m-gaps">
        <Container>
          <div>
            <h2 style={{ color: "white" }}>OFF Plan</h2>

            <div className="row g-4">
              {datas.map((deal: any) => (
                <>
                  <div className="col-lg-4 col-md-6 off">
                    <div>
                      <Card
                        key={deal?.id}
                        className="filter-card"
                        cover={
                          <img
                            src={deal?.image[0]}
                            className="img-fluid img-proper"
                            alt="img"
                            loading="lazy"
                          />
                        }
                      >
                        <h6
                          className="txt-f"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleNav(deal.id)}
                        >
                          {deal?.title}
                        </h6>
                        <p className="txt-f-1">{deal?.location}</p>
                        <div className="d-flex justify-content-between">
                          <p>{deal?.category}</p>
                          <div className="d-flex gap-3 ">
                            <div>
                              <a href="https://www.facebook.com/profile.php?id=61565695876899">
                                <i className="fi fi-brands-facebook  f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://x.com/dimoora68987">
                                <i className="fi fi-brands-twitter-alt f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://www.youtube.com/@Dimoora">
                                <i className="fi fi-brands-youtube f-icon"></i>
                              </a>
                            </div>
                            <div>
                              <a href="https://www.instagram.com/dimoora_property_sales/">
                                <i className="fi fi-brands-instagram f-icon"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
